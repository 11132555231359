import React from 'react'

import { useState } from 'react'
// import Banner from './Banner.js'

import { Link, useNavigate } from 'react-router-dom'

// import Logo from '../assets/LOGO_crop.png'
import Logo from '../assets/Juicy-Logo-anim-TransparentBackgound-White-HD.gif'

// const bottom = 'bottom'
// const top = 'top'


function Home() {

  const navigate = useNavigate()
  const [exiting, setExiting] = useState(false)

  function delayRoute(e, path) {
    e.preventDefault()
    setExiting(true)
    setTimeout(() => navigate(path), 600)
  }

  return (
    <div className='content'>
      {/* <div className={`background-color home-page ${exiting ? `exiting` : ``}`}></div> */}
      <div className={`image-wrapper ${exiting ? `exiting` : ``}`}>
        {/* className={`mix-artwork ${mixOpening ? `open` : `closed`}`} */}
        <img className="logo" src={Logo} alt="logo" />
      </div>

      {/* <div className='info'>
        <h4>Website coming soon.</h4>
        <p>+49 176 6475 3408</p>
      </div> */}

      <nav>
        <ul className={`nav-item ${exiting ? `exiting` : ``}`}>


          <li className='nav-item'>
            <Link
              to="/about"
              onClick={(e) => delayRoute(e, "/about")}
            >
              About</Link></li>


          <li className='nav-item'>
            <Link
              to={`/book/`}
              onClick={(e) => delayRoute(e, "/book")}
            >Book </Link></li>

          <li className='nav-item'>
            <a
              href="https://mailchi.mp/3254224be5ab/juicy-yoga-sign-up-2"
              target="_blank"
              rel="noreferrer">
              Newsletter</a>
          </li>

          <li className='nav-item'>
            <Link
              to={`/connect/`}
              onClick={(e) => delayRoute(e, "/connect")}
            >Connect </Link></li>


        </ul>
      </nav>
    </div>




  )
}
export default Home