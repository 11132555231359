import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import { Link } from 'react-router-dom'

import Banner from './components/Banner.js'

import Home from './components/Home'

import About from './components/About'
import Book from './components/Book'
import Newsletter from './components/Newsletter'
import Connect from './components/Contact'

import HoldingPage from './components/HoldingPage'

function App() {
  return (
    <BrowserRouter>
      <main>
      <Banner level={'top'} />
      {/* <Banner level={'bottom'} /> */}
      <Routes>
        <Route exact path="/home/" element={<Home />} />
        <Route exact path="/" element={<HoldingPage />} /> 
        <Route path="/about/" element={<About />} />
        <Route path="/book/" element={<Book />} />
        <Route path="/newsletter/" element={<Newsletter />} />
        <Route path="/connect/" element={<Connect />} />
      </Routes>
      <Banner level={'bottom'} />
      </main>
    </BrowserRouter>

  )
}

export default App;