import React from 'react'

// import { useState } from 'react'
// import Banner from './Banner.js'

// import { Link, useNavigate } from 'react-router-dom'

import logoOriginal from '../assets/LOGO_crop.png'
// import Logo from '../assets/Juicy-Logo-anim-TransparentBackgound-White-HD.gif'

// const bottom = 'bottom'
// const top = 'top'


function HoldingPage() {

  // const navigate = useNavigate()
  // const [exiting, setExiting] = useState(false)

  // function delayRoute(e, path) {
  //   e.preventDefault()
  //   setExiting(true)
  //   setTimeout(() => navigate(path), 600)
  // }

  return (

    <div className='holding-section'>

      <div className='img-wrapper'>
        <img className="logoOriginal" src={logoOriginal} alt="logo" />
      </div>



      <div className='info'>
        <h4>Website coming soon.</h4>
        <p>+49 176 6475 3408</p>
      </div>

    </div>








  )
}
export default HoldingPage