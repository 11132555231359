import React from 'react'
// import { useState } from 'react'

// import aboutImage from '../assets/Juicy-Behance2-04.png'
import aboutImage from '../assets/image3.jpeg'
import backArrow from '../assets/icons8-back-100.png'

import { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'


function About() {

  const navigate = useNavigate()
  const [exiting, setExiting] = useState(false)

  function delayRoute(e, path) {
    e.preventDefault()
    setExiting(true)
    setTimeout(() => navigate(path), 600)
  }

  return (
    <div className={`content ${exiting ? `exiting` : ``}`}>
      {/* <div className={'background-color about-page'}></div> */}
      <div className='back-button'>
        <Link
          to={`/home/`}
          onClick={(e) => delayRoute(e, "/home")} >
          <img className='back-image' src={backArrow} alt="back" />
          <p>Home</p>
        </Link>
      </div>


      <div className='image-wrapper'>
        <img className='about-image' src={aboutImage} alt="logo" />
      </div>
      <div className='text-wrapper'>
        {/* <p>
          Juicy Yoga was founded by Delphine Hazledine. It’s aim is to support local c o m m u n i t i e s, offering a safe and soothing space to s l o w down.{'\n'}
          {'\n'}
          Through intertwining soundscapes, somatic movement, b r e a t h w o r k and restorative yoga her classes provide versatility and create a moving meditation. {'\n'}
          {'\n'}
          Delphine has taught extensively in yoga studios, primary schools, universities, in fields at festivals, on permaculture farms and in hotels for corporate events. She has worked closely with brands like Lululemon on public events and facilitated a national recruitment campaign with Ernst & Young promoting wellbeing in the workplace.{'\n'}
          {'\n'}
          Most recently she competed multiple trainings in Nuard Bo-Rarn, a traditional form of Thai Yoga Massage. She incorporates this framework with her degree in Dance and holistic knowledge to offer individual b o d y w o r k sessions.{'\n'}
          {'\n'}
          Delphine lives in Berlin. She is an ambient DJ and hosts a monthly radio show on Refuge Worldwide.
        </p> */}

        <p>
          Juicy Yoga was founded by Delphine Hazledine. It’s aim is to support local c o m m u n i t i e s by offering a safe and soothing space to s l o w down.{'\n'}
          {'\n'}
          Through intertwining soundscapes, somatic movement, b r e a t h w o r k and restorative yoga her classes provide versatility and often create a m o v i n g meditation.{'\n'}
          {'\n'}
          Delphine has taught extensively in yoga studios, primary schools, universities, in fields at festivals, on permaculture farms and in hotels for corporate events. She has worked closely with brands like Lululemon on public events and facilitated a national recruitment campaign with Ernst & Young promoting wellbeing in the workplace.{'\n'}
          {'\n'}
          Most recently she competed multiple trainings in Nuard Bo-Rarn, a traditional form of thai yoga massage. She incorporates this framework with her degree in Dance and holistic knowledge to offer individual bodywork sessions.{'\n'}
          {'\n'}
          Delphine lives in Berlin. She is an ambient DJ and hosts a monthly radio show on Refuge Worldwide.{'\n'}
        </p>



      </div>
    </div>
  )
}



// {'\n'}
export default About