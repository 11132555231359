import React from 'react'

// import bookImage from '../assets/image2.jpeg'
import bookImage from '../assets/image2-crop.jpeg'
import backArrow from '../assets/icons8-back-100.png'

import { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'

function Book() {

  const navigate = useNavigate()
  const [exiting, setExiting] = useState(false)

  function delayRoute(e, path) {
    e.preventDefault()
    setExiting(true)
    setTimeout(() => navigate(path), 600)
  }

  return (
    <div className={`content ${exiting ? `exiting` : ``}`}>

      <div className='back-button'>
        <Link
          to={`/home/`}
          onClick={(e) => delayRoute(e, "/home")} >
          <img className='back-image' src={backArrow} alt="back" />
          <p>Home</p>
        </Link>
      </div>
      <div className='book-section'>

        <div className='book-option'>
          <div className='image-wrapper'>
            <img className='book-image' src={bookImage} alt="logo" />
          </div>
          <div className={'background-color book-page'}></div>
          {/* <div className='image-wrapper'>
        <img className='about-image' src={aboutImage} alt="logo" />
      </div> */}
          <div className='text-wrapper'>
            <p>
              Join the Juicy Community every Friday Evening!{'\n'}
              {'\n'}
              Price: €8/€10/€12 per session (depending on your income){'\n'}
              Time: 20:00{'\n'}
              Location: Bija Yoga, Grimmstraße 19, Berlin, 10967{'\n'}
              {'\n'}
              Suitable for all levels!{'\n'}
            </p>
          </div>
        </div>

        <div className='book-option'>
          <div className='image-wrapper'>
            <img className='book-image' src={bookImage} alt="logo" />
          </div>
          <div className={'background-color book-page'}></div>
          {/* <div className='image-wrapper'>
        <img className='about-image' src={aboutImage} alt="logo" />
      </div> */}
          <div className='text-wrapper'>
            <p>
              Individual Breath & Bodywork Sessions*{'\n'}
              {'\n'}
              These involve preventative, therapeutic and rhythmic stretching of the w h o l e body and follows the traditional Thai ‘sen’ lines. Each session is tailored to your specific desires & is traditionally performed fully clothed.{'\n'}
              {'\n'}
              Price: 1€ per minute (minimum 1 hour){'\n'}
              Location: Neukölln{'\n'}
              Availability: Monday - Sunday!{'\n'}
              *FLINTA only{'\n'}
              {'\n'}
            </p>
          </div>
        </div>

      </div>


    </div>
  )
}
export default Book