import React from 'react'

function Banner({ level }) {

  return (
    <div className={`scroll-banner-${level}`}>
      <div className='scroll-content'>
        {/* <div className={`scroll-container-${level}`}>
          <h1>
            <p className='scroll-text'>Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - </p>
          </h1>
          <h1>
            <p className='scroll-text'>Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - Yoga - Breath - Bodywork - </p>
          </h1>
        </div> */}

        <div className={`scroll-container-${level}`}>
          <h1>
            <p className='scroll-text'>Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ </p>
          </h1>
          <h1>
            <p className='scroll-text'>Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ Yoga ~ Breath ~ Bodywork ~ </p>
          </h1>
        </div>



      </div>
    </div>
  )
}


export default Banner